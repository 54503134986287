import React, { useCallback, useState } from 'react'
import classnames from 'classnames'
import Cookies from 'js-cookie'
import queryString from 'query-string'
import { ColorExtractor } from 'react-color-extractor'
import { addDecimalSeparator } from '../utils'
import Color from 'colorjs.io'

import { ShowcaseDisplayTypeID, Showcase, Offer, Link, LinksByOfferId, ShowcaseTypes } from '../types'
import { useGtmPush } from '../hooks/app.hook'

import basicClasses from './Basic.module.scss'
import RecommendedIcon from './images/recommendedIcon.svg'

const DEFAULT_ACCENT_COLOR = '#f2f2f2'

type ClassTable = {
  [classId: string]: string
}

export type ShowcaseTemplateProps = React.PropsWithChildren<{
  showcase: Showcase | null,
  offers: Offer[],
  linksByOfferId: LinksByOfferId,
  classes?: ClassTable,
}>

export type ShowcaseTemplateComponent = (props: ShowcaseTemplateProps) => JSX.Element

export default function Basic({ showcase, offers, linksByOfferId, classes = {} }: ShowcaseTemplateProps): JSX.Element {
  const mergedClasses = Object.keys(classes).reduce((currentClasses, classId) => ({
    ...currentClasses,
    [classId]: classnames(currentClasses[classId], classes[classId]),
  }), basicClasses)

  const advantages = showcase?.advantages.filter(advantage => !!advantage) ?? []

  const [isCreditorInfoExpanded, setIsCreditorInfoExpanded] = useState<boolean>(false)

  const handleCreditorHeaderClick = useCallback(() => {
    setIsCreditorInfoExpanded(!isCreditorInfoExpanded)
  }, [isCreditorInfoExpanded, setIsCreditorInfoExpanded])

  return (
    <div className={mergedClasses.showcase}>
      {showcase?.logo?.[0] ?
        (
          <div className={mergedClasses.logoWrapper}>
            <div className={mergedClasses.logo}>
              <img className={mergedClasses.logoImage} src={showcase?.logo?.[0]?.source} alt={showcase?.title} />
            </div>
          </div>
        )
        :
        null
      }

      <div className={mergedClasses.headerWrapper}>
        <div className={mergedClasses.header}>
          <div className={mergedClasses.headerInnerWrapper}>
            <div className={mergedClasses.banner}>
              <div
                className={mergedClasses.bannerImage}
                style={showcase?.banner?.[0] ? { backgroundImage: `url(${showcase.banner[0].source})` } : undefined}
              ></div>
            </div>
            <h1 className={mergedClasses.headline}>
              {showcase?.title}
            </h1>
            {advantages.length ?
              (
                <ul className={mergedClasses.advantagesList}>
                  {advantages.map((advantage, index) => (
                    <li className={mergedClasses.advantage} key={index}>
                      {advantage}
                    </li>
                  ))}
                </ul>
              )
              :
              null
            }
          </div>
        </div>
      </div>

      {offers?.length ?
        (
          <>
            <div className={mergedClasses.banksListWrapper}>
              <div className={classnames(mergedClasses.banksList, {
                [mergedClasses.bankListCard]: showcase?.display_type_id === ShowcaseDisplayTypeID.Card,
                [mergedClasses.bankListTable]: showcase?.display_type_id === ShowcaseDisplayTypeID.Table,
              })}>
                {offers.map((offer, index) => (
                  <BankCard
                    showcase={showcase}
                    offer={offer}
                    link={linksByOfferId?.[offer.id] ?? null}
                    classes={mergedClasses}
                    key={offer.id}
                    index={index}
                  />
                ))}
              </div>
            </div>

            <div className={classnames(mergedClasses.creditorInfos, {
              [mergedClasses.creditorInfosExpanded]: isCreditorInfoExpanded,
            })}>
              <h2 className={mergedClasses.creditorInfoHeader} onClick={handleCreditorHeaderClick}>
                Информация о кредиторах
                <svg className={mergedClasses.creditorInfoHeaderExpander} focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                  <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z" fill="currentColor"></path>
                </svg>
              </h2>
              <div className={mergedClasses.creditorInfosList}>
                {offers.map((offer, index) => (
                  <CreditorInfo offer={offer} classes={mergedClasses} key={index} />
                ))}
              </div>
            </div>
          </>
        )
        :
        null
      }

      <div className={mergedClasses.disclaimer}>
        {showcase?.disclaimer}
      </div>

      {showcase?.documentation?.[0] ?
        (
          <div className={mergedClasses.documentation}>
            <a href={showcase?.documentation[0].source} target="_blank" rel="noopener noreferrer">Подробная информация</a>
          </div>
        )
        :
        null
      }

      <CookieWarning classes={mergedClasses} />
    </div>
  )
}

const CUSTOM_BANK_FIELDS = [
  {
    code: 'amount',
    name: 'Сумма',
  },
  {
    code: 'credit_limit',
    name: 'Кредитный лимит',
  },
  {
    code: 'cash_withdrawal',
    name: 'Снятие наличных',
  },
  {
    code: 'interest_rate',
    name: 'Ставка',
    color: 'primary'
  },
  {
    code: 'annual_income',
    name: 'Доход (годовых)',
  },
  {
    code: 'term',
    name: 'Срок',
  },
  {
    code: 'maintenance',
    name: 'Обслуживание',
  },
  {
    code: 'interest_free_period',
    name: 'Дней без %',
  },
  {
    code: 'down_payment',
    name: 'Первоначальный взнос',
  },
]

export type BankCardProps = React.PropsWithChildren<{
  showcase: Showcase | null,
  offer: Offer,
  link: Link | null,
  classes?: ClassTable,
  index: number
}>

export function BankCard({ showcase, offer, link, classes = basicClasses, index = 0 }: BankCardProps): JSX.Element {
  const gtmPush = useGtmPush();
  const [dominantColors, setDominantColors] = useState<string[]>([]);
  const isRecommended =
    showcase?.type === ShowcaseTypes.universal
      ? index < showcase?.recommended_offers_count
      : showcase?.recommended_offers?.includes(Number(offer.id)) ?? false

  const logoSrc = offer.icons.favicon_svg?.path ?? offer.icons.favicon_orig?.path ?? offer.favicon?.[0]?.source
  const iconSrc = offer.favicon?.[0]?.source ?? offer.icons.favicon_svg?.path ?? offer.icons.favicon_orig?.path ?? ''

  const handleClick = useCallback(() => {
    gtmPush({
      'event': 'redirect',
      'event_name': 'redirect',
      'offer_id': offer.id,
      'offer_name': offer.name
    })
  }, [offer.id, offer.name, gtmPush])

  const handleColorsExtract = useCallback((colors: string[]) => {
    setDominantColors(colors)
  }, [setDominantColors])

  const accentColor = new Color(dominantColors[0] ?? DEFAULT_ACCENT_COLOR)
  accentColor.lighten(.25)
  const backColor = accentColor.toString({ format: 'hex' })

  return (
    <div style={{ '--back-color': backColor === '#fff' ? DEFAULT_ACCENT_COLOR : backColor }} className={classnames(classes.bankWrapper, { [classes.recommendedBankWrapper]: isRecommended })}>
      {isRecommended ?
        (
          <div className={classes.bankRecommendedLabel}>
            <img src={RecommendedIcon} alt="" />
            {showcase?.recommended_label}
          </div>
        )
        :
        null
      }
      <div className={classes.recommendedBankLogoImageWrapper}>
      {isRecommended && (iconSrc || logoSrc) ?
        (
            <>
            <ColorExtractor
              src={`/api/icon/?url=${encodeURIComponent(iconSrc)}`}
              getColors={handleColorsExtract}
              onError={(err: any) => console.log('err', err)}
            />
            <img className={classes.bankIconImage} src={iconSrc} alt={offer.name} />
            <img className={classes.bankLogoImage} src={logoSrc} alt={offer.name} />
            </>
          ) : null
        }
      </div>
      <div className={classnames(classes.bank, { [classes.recommendedBank]: isRecommended })}>
        <div className={classes.bankName}>
          <div className={classes.bankLogo}>
            {iconSrc && !isRecommended ?
              (
                <div className={classes.bankLogoImageWrapper}>
                  <img className={classes.bankIconImage} src={iconSrc} alt={offer.name} />
                  <img className={classes.bankLogoImage} src={logoSrc} alt={offer.name} />
                </div>
              )
              :
              null
            }
            <span>{offer.custom_fields?.showcase_name ?? offer.name}</span>
          </div>
        </div>

        <div className={classes.bankPropsList}>
          {CUSTOM_BANK_FIELDS.map(({ code, name, color }) => {
            const displayValue = getCustomFieldValue(offer, code)

            return displayValue ?
              (
                <div key={code} className={classes.bankProp}>
                  <span className={classes.bankPropName}>
                    {name}
                  </span>
                  {' '}
                  <span className={classnames(classes.bankPropValue, { [classes.bankPropValuePrimary]: color === 'primary' })}>
                    {displayValue}
                  </span>
                </div>
              )
              :
              null
          })}
        </div>

        {(showcase && link) ?
          (
            <div className={classes.bankButtonsList}>
              <OfferLink showcase={showcase} link={link} className={classes.button} onClick={handleClick} target="_blank" rel="noopener noreferrer">Оформить</OfferLink>
            </div>
          )
          :
          null
        }
      </div>
    </div>
  )
}

export type OfferLinkProps = Omit<React.HTMLProps<HTMLAnchorElement>, 'href'> & {
  showcase: Showcase,
  link: Link,
}

export function OfferLink({ showcase, link, children, ...rest }: OfferLinkProps): JSX.Element {
  const url = link.url

  const subaccounts = {
    sa: showcase.subaccount || '',
    sa2: showcase.subaccount2 || '',
    sa3: showcase.subaccount3 || '',
    sa4: showcase.subaccount4 || '',
    sa5: showcase.subaccount5 || '',
  }

  const subaccountsQueryString = queryString.stringify(subaccounts, { skipEmptyString: true })

  const href = subaccountsQueryString ? url + (url.indexOf('?') >= 0 ? '&' : '?') + subaccountsQueryString : url

  return (
    <a {...rest} href={href}>{children}</a>
  )
}

const CUSTOM_CREDITOR_FIELDS = [
  {
    code: 'legal_entity_name',
    name: 'Наименование ЮЛ',
  },
  {
    code: 'license_number',
    name: '№ лицензии',
  },
  {
    code: 'legal_address',
    name: 'Юридический адрес',
  },
  {
    code: 'max_annual_interest_rate',
    name: 'Максимальная годовая процентная ставка',
  },
  {
    code: 'term_with_extension',
    name: 'Срок с продлением',
  },
  {
    code: 'max_loan_term',
    name: 'Максимальный срок займа',
  },
]

export type CreditorInfoProps = React.PropsWithChildren<{
  offer: Offer,
  classes?: ClassTable,
}>

export function CreditorInfo({ offer, classes = basicClasses }: CreditorInfoProps): JSX.Element {
  return (
    <div className={classes.creditorInfo}>
      <h3 className={classes.creditorHeader}>
        {offer.name}
      </h3>
      {CUSTOM_CREDITOR_FIELDS.map(({ code, name }) => {
        const displayValue = getCustomFieldValue(offer, code)

        return displayValue ?
          (
            <div key={code} className={classes.creditorProp}>
              <span className={classes.creditorPropName}>
                {name}:
              </span>
              {' '}
              <span className={classes.creditorPropValue}>
                {displayValue}
              </span>
            </div>
          )
          :
          null
      })}
    </div>
  )
}

const CUSTOM_FIELDS_PERCENTS = [
  'max_annual_interest_rate',
  'interest_rate',
  'annual_income',
  'down_payment',
]

type FieldSuffix = string | string[]

const CUSTOM_FIELD_SUFFIX_MAP: { [key: string]: FieldSuffix } = {
  rouble: '₽',
  dollar: '$',
  euro: '€',
  days: ['день', 'дня', 'дней'],
  weeks: ['неделя', 'недели', 'недель'],
  months: ['месяц', 'месяца', 'месяцев'],
  years: ['год', 'года', 'лет'],
}

function getCountedSuffix(count: number, suffix: FieldSuffix | null, isNominal: boolean = true): string {
  if (!suffix) {
    return ''
  }

  if (typeof suffix === 'string') {
    return suffix
  }

  if (count % 100 < 10 || count % 100 > 20) {
    if (count % 10 === 1) {
      return isNominal ? suffix[0] : suffix[1]
    }

    if (count % 10 === 2 || count % 10 === 2 || count % 10 === 3) {
      return suffix[1]
    }
  }

  if (count !== Math.floor(count)) {
    return suffix[1]
  }

  return suffix[2]
}

export function getCustomFieldValue(offer: Offer, propName: string): JSX.Element | null {
  const fieldCurrency = offer.custom_fields?.[`${propName}_currency`]
  const fieldUnit = offer.custom_fields?.[`${propName}_unit`]

  const fieldSuffix = CUSTOM_FIELDS_PERCENTS.includes(propName) ?
    '%' :
    fieldCurrency ?
      fieldCurrency as string :
      fieldUnit ? CUSTOM_FIELD_SUFFIX_MAP[fieldUnit as string] : null

  const fieldValue = offer.custom_fields?.[propName]

  if (fieldValue !== null && fieldValue !== undefined) {
    return (
      <>
        {addDecimalSeparator(fieldValue)}&nbsp;{getCountedSuffix(Number(fieldValue), fieldSuffix)}
      </>
    )
  }

  const fieldValueFrom = offer.custom_fields?.[`${propName}_from`]
  const fieldValueTo = offer.custom_fields?.[`${propName}_to`]

  if (fieldValueFrom !== null && fieldValueFrom !== undefined && fieldValueTo !== null && fieldValueTo !== undefined) {
    if (fieldValueFrom === fieldValueTo) {
      return (
        <>
          {addDecimalSeparator(fieldValueFrom)}&nbsp;{getCountedSuffix(Number(fieldValueFrom), fieldSuffix)}
        </>
      )
    }
    return (
      <>
        от&nbsp;{addDecimalSeparator(fieldValueFrom)} до&nbsp;{addDecimalSeparator(fieldValueTo)}&nbsp;{getCountedSuffix(Number(fieldValueTo), fieldSuffix, false)}
      </>
    )
  }

  if (fieldValueFrom !== null && fieldValueFrom !== undefined) {
    return (
      <>
        от&nbsp;{addDecimalSeparator(fieldValueFrom)}&nbsp;{getCountedSuffix(Number(fieldValueFrom), fieldSuffix, false)}
      </>
    )
  }

  if (fieldValueTo !== null && fieldValueTo !== undefined) {
    return (
      <>
        до&nbsp;{addDecimalSeparator(fieldValueTo)}&nbsp;{getCountedSuffix(Number(fieldValueTo), fieldSuffix, false)}
      </>
    )
  }

  return null
}

export type CookieWarningProps = React.PropsWithChildren<{
  classes?: ClassTable,
}>

const COOKIE_ACCEPTED = 'yes'

export function CookieWarning({ classes = basicClasses }: CookieWarningProps): JSX.Element | null {
  const [open, setOpen] = useState<boolean>(Cookies.get('cookie-accepted') !== COOKIE_ACCEPTED)

  const handleClose = useCallback(() => {
    Cookies.set('cookie-accepted', COOKIE_ACCEPTED, { expires: 30 })
    setOpen(false)
  }, [setOpen])

  if (!open) {
    return null
  }

  return (
    <div className={classes.cookieWarning}>
      Продолжая использовать наш сайт, вы даете согласие на обработку файлов cookie, пользовательских данных
      (сведения о местоположении; тип и версия ОС; тип и версия Браузера; тип устройства и разрешение его экрана;
      источник откуда пришел на сайт пользователь; с какого сайта или по какой рекламе; язык ОС и Браузера;
      какие страницы открывает и на какие кнопки нажимает пользователь; ip-адрес) в целях функционирования сайта,
      проведения ретаргетинга и проведения статистических исследований и обзоров. Если вы не хотите, чтобы ваши данные
      обрабатывались, покиньте сайт.

      <div className={classes.cookieWarningButtons}>
        <button type="button" className={classes.button} onClick={handleClose}>Принимаю</button>
      </div>
    </div>
  )
}
